// REQUIRED EVENTHANDLER.JS

WeatherTech = function(year, make, model){
    var _form = $('<div class="c-smart-search flex-j-center" style="display:none"></div>')
    var _xhr = null;
    var _timeout=null;

    if(isNaN(parseInt(year))) year = '';
    if(typeof make != 'string') make = '';
    if(typeof model != 'string') model = '';
    this.show = function(){
        render = _form;
        $('body').append(render);
        ref = this;
        render.fadeIn(function(){
            /*document.ScrollContoller.disableScroll();
            if(ref instanceof HTMLInputElement &&  ref.tagName.toLowerCase() == 'input'){
                _form.find('.js-search-ai input[type=search]').val(ref.value);
                ref.value = '';
            }
            _form.find('.js-search-ai input[type=search]').focus();*/
        });

        callAPI('/ajax/weathertechForm/',{year:year,make:make,model:model},function($data){
            var modal_vehicle = $('<div class="c-smart-search--modal append"></div>');
            if($data.data != null){
                displayGroups(year+' '+make+' '+model,$data.data);
            }
            else{
                _form.append('<div class="c-smart-search--title">'+WeatherTech.translator.trans('Please specify your vehicle')+'</div>');
                modal_vehicle.append(vehicleData($data.vehicle,$data.step2,$data.isNA,$data.token));
            }
            _form.append(modal_vehicle);
        })
    }

    this.hide = function(){
        if(_xhr)_xhr.abort();
        if(_timeout) clearTimeout(_timeout);
        _form.find('.input-search-bar button.search .fa').addClass('fa-search').removeClass(' fa-circle-o-notch fa-spin fa-pencil');
        _form.fadeOut(function(){
            _form.find('.c-smart-search--modal.append').remove();
            _form.find('.c-smart-search--title').remove();
        })
        document.ScrollContoller.enableScroll();
    }

    _form.on('click','button.close',function(e){
        e.preventDefault();
        this.hide();
    }.bind(this));


    function callAPI(uri,data,callback){
        var FD = new FormData();
        for(var key in data){
            FD.append(key,data[key]);
        }

        var request;
        if (window.ActiveXObject)
            request = new ActiveXObject('Microsoft.XMLHTTP');
        else
            request = new XMLHttpRequest();

        $('body').append([
            '<div class="ajax-loading" style="display:block">',
            '<div class="overlay"></div>',
            '<div class="content" style="text-align: center">',
            '<div class="content__align">',
            '<i class="fa fa-circle-o-notch fa-spin fa-3x white" aria-hidden="true"></i>',
            '</div>',
            '</div>',
            '</div>'
        ].join(''));
        
        request.open("POST",uri);
        request.onreadystatechange = function() {
            if (request.readyState == XMLHttpRequest.DONE) {
                $('body').children('.ajax-loading').remove();
                if(callback!= undefined){
                    callback(JSON.parse(request.responseText));
                }
            }
        };
        request.send(FD);
    }

    // Year make model
    function vehicleData($vehicle){
        var container = $('<form class="vehicule-definition" method="POST"></form>');
        var selection = container.find('[name=selection]')
        container.on('submit',function(e){
            e.preventDefault();
            if(!year.val()){
                year.open();
                return false;
            }
            else if(!make.val()){
                make.open();
                return false;
            }
            else if(!model.val()){
                model.open();
                return false;
            }
            
            callAPI('/ajax/weathertechForm',{year:year.val(),make:make.val(),model:model.val()},function($data){
                _form.find('.c-smart-search--title').remove();
                displayGroups(year.val()+' '+make.val()+' '+model.val(),$data.data);
            });
        })
        if($vehicle.year){
            var year = new vehicleFieldData($vehicle.year,'js-year year',WeatherTech.translator.trans('Year'));
            container.append(year.getObject());
            year.addEvent(function(){
                if(!make.update({year:year.val()}))
                    model.update({year:year.val(), make:make.val()});
                container.submit();
            });
        }
        if($vehicle.make){
            var make = new vehicleFieldData($vehicle.make,'js-make make',WeatherTech.translator.trans('Make'));
            container.append(make.getObject());
            make.addEvent(function(){
                model.update({year:year.val(), make:make.val()});
                container.submit();
            });
        }
        if($vehicle.model){
            var model = new vehicleFieldData($vehicle.model,'js-model model',WeatherTech.translator.trans('Model'));
            container.append(model.getObject());
            model.addEvent(function(){
                container.submit();
            });
        }
        container.append('<button class="close js-close"><i class="fa fa-close"></i></button>');
        return container;
    }

    function vehicleFieldData($field, $class,$text){
        var render = false;
        if(!isNaN($field) || typeof $field == 'string'){
            render = $('<div class="field-group"><input type="text" class="'+$class+'" value="'+$field+'" readonly ></div>');
        }
        else if(Array.isArray($field)){
            render = $('<div class="field-group selectbox "><select class="'+$class+'" tabindex="-1" aria-hidden="true"><option value="">'+$text+'</option></select></div>');
            $field.forEach(function(el){
                render.find('select').append('<option class="js-label" value="'+el+'">'+el+'</option>')
            });
            render.find('select').select2();
        }
        else if($field.ajax){
            render = $('<div class="field-group selectbox"><select class="'+$class+'" tabindex="-1" aria-hidden="true"><option value="">'+$text+'</option></select></div>');
            render.find('select').select2();
        }

        this.getObject = function(){
            return render;
        }
        this.addEvent = function(calback){
            return render.find('input,select').on('change',calback);
        }
        this.val = function(){
            return render.find('input,select').val();
        }

        this.open = function(){
            var target = render.find('select');
            if(!target.val()){
                target.select2("close");
                target.select2("open");
            }
        }

        this.update = function($data){
            if($field.ajax){
                target = render.find('select')
                target.attr('disabled',true);
                callAPI($field.ajax, $data,function($rsp){
                    $key = $text.toLowerCase();
                    switch($key){
                        case 'année':
                            $key = 'year';
                            break;
                        case 'fabricant':
                            $key = 'make';
                            break;
                        case 'modèle':
                            $key = 'model';
                            break;
                    }
                    var html = '<option class="js-label" value="">' + $text + '</option>';
                    for (var i = 0; i < $rsp.vehicle[$key].length; i++)
                    {
                        html += '<option value="' + $rsp.vehicle[$key][i] + '">' + $rsp.vehicle[$key][i] + '</option>';
                    }
                    target.html(html);
                    target.attr('disabled',false);
                    if(target.find('option').length>1){
                        this.open();
                    }
                }.bind(this))
                return true;
            }
            return false;
        }
    }


    //function Get Question
    function displayGroups(vName,$group){
        _form.find('.smart-search-result.append').remove();
        if($group === null){
            return;
        }
        var block = $('<div class="c-smart-search--modal smart-search-result append"><div class="box">'+(!_form.find('button.close').length?'<button class="close"><i class="fa fa-close"></i></button>':'')+'<div class="right-side"><div class="h3"><b>'+WeatherTech.translator.trans('WeatherTech Products for:')+'</b> '+vName+'</div><ul class="flex px2"></ul></div></div></div>');
        _form.append(block);
        $group.Groups.forEach(function(e){
            var li = document.createElement("li");
            li.className="l--banner-weathertech-items";
            var link = document.createElement("a");
            link.className = 'box';
            link.href = "#";
            link.innerHTML = '<img src="'+e.GroupImage+'" />'+'<span>'+e.GroupName+'</span>';
            link.addEventListener("click",function(){
                var popup = new WeatherTech.Popup($group.VehicleId,$group.Year,{Groups:[e],Status:$group.Status,VehicleId:$group.VehicleId,Year:$group.Year,statusCode:$group.statusCode});
                popup.setVehiculeName(vName)
                popup.toggle();
                popup.addEventListener("success",function(e){
                    var form = $('<form action="'+$group.URL+'" method="post" style="display:none">' +
                    '<textarea name="Selection"></textarea>' +
                    '</form>');
                    $('body').append(form);
                    form.find('textarea').val(JSON.stringify(e.detail.Parts));
                    form.submit();
                    $('body').append([
                        '<div class="ajax-loading" style="display:block">',
                        '<div class="overlay"></div>',
                        '<div class="content" style="text-align: center">',
                        '<div class="content__align">',
                        '<i class="fa fa-circle-o-notch fa-spin fa-3x white" aria-hidden="true"></i>',
                        '</div>',
                        '</div>',
                        '</div>'
                    ].join(''));
                });
            });
            li.appendChild(link);
            block.find('ul').append(li);
        })
    }
}

WeatherTech.Popup = function(vID,y,api){
    if(!(this instanceof WeatherTech.Popup))
        throw new Error("Uncaught TypeError: Popup is a construtor.");
    new EventHandler(this);

    var element = document.createElement("div"),
        trans = WeatherTech.translator.trans
        _=this;
    element.className="c--popup c--popup-choices js-popup";
    var content =
    '<div class="c--popup----bg js--close"></div>'+
    '<div class="c--popup-box">'+
        '<span class="js--close">'+trans('Close')+' <i class="fa fa-close"></i></span>'+
        '<div class="c-popup-content">'+
        '</div>'+
    '</div>';
    element.innerHTML=content;
    vehicleName = "vehicle";
    grpName = "Search";

    function onError(){
        element.querySelector(".c-popup-content").innerHTML=
            '<div class="c--popup-error"><b>'+grpName+'</b> '+trans('is not available for your')+' <b>'+vehicleName+'</b>.</div>';
    }

    function Loading(){
        element.querySelector(".c--popup-box").style.display="none";
    }

    function loadQuestions(id){
        if(!api.Groups[id].Options.length)
            Loading();
        var q = new WeatherTech.Questions(vID,y,api.Groups[id]);
        grpName = api.Groups[id].GroupName;
        element.querySelector(".c-popup-content").appendChild(q.getForm());
        q.addEventListener("error",function(){
            element.querySelector(".c--popup-box").style.display="block";
            element.querySelector(".c-popup-content").removeChild(q.getForm());
            onError();
        });
        q.addEventListener("success",function(evt){
            _.close();
            _.dispatchEvent("success",evt.detail);
        });
        q.addEventListener("loading",Loading);
        return q;
    }

    if(api.Groups.length>1){
        var g = new WeatherTech.Groups(api.Groups);
        element.querySelector(".c-popup-content").appendChild(g.getForm());
        g.addEventListener("selectGrp",function(e){
            element.querySelector(".c-popup-content").removeChild(g.getForm());
            loadQuestions(e.detail.grpID);
        })
    }
    else if(api.Groups.length==1){
        loadQuestions(0);
    }
    else{
        onError();
    }

    this.setVehiculeName = function(string){
        vehicleName = string;
        return this;
    };

    this.getElement = function(){
        return element;
    }

    this.toggle = function(){
        if(element.classList.contains("js--is-open"))
           return  this.close();
        else
            return this.open();
    }
    this.open = function(){
        element.classList.add("js--is-open");
        return this;
    }
    this.close = function(){
        element.classList.remove("js--is-open");
        return this;
    }
    this.die = function(){
        element.parentElement.removeChild(element);
    }

    /*if(btn!=undefined)
        btn.addEventListener("click", function(e){
            e.preventDefault();
            this.toggle();
        }.bind(this));*/

    element.querySelectorAll(".js--close").forEach(function(e){
        e.addEventListener("click",function(e){
            e.preventDefault();
            this.close();
            this.die();
        }.bind(this))
    }.bind(this));

    document.querySelector("body").appendChild(element);
    
    return this;
}

WeatherTech.Groups = function(Gs){
    if(!(this instanceof WeatherTech.Groups))
        throw new Error("Uncaught TypeError: Groups is a construtor.");
    new EventHandler(this);
    var data = {},
        form =  document.createElement("form");
    
    form.className="c--popup-questions";
    form.method = "POST";
    var content = '<ol>';
        content +=  '<li data-name="">'+
                        '<div class="c--popup-title">Choose a product type.</div>'+
                        '<ul class="c--popup-choices----list mb2">';
        Gs.forEach(function(ch,i){
                           
            content +=      '<li class="c--popup-choices----item">'+
                                '<label>'+
                                    '<input name="grpID" value="'+i+'" type="radio">'+
                                    '<i class="fa fa-check"></i>'+
                                    '<img src="https:'+ch.GroupImage+'" alt="">'+
                                    '<span>'+ch.GroupName+'</span>'+
                                '</label>'+
                            '</li>';
        });
        content +=      '</ul>';
                        
        content +=      '<button class="btn btn--search2" type="submit">Continue</button>'
                        
         content +=  '</li>';                  
    content+=   '</ol>';
    form.innerHTML = content; 
    
    function setCurrent(id){
        form.querySelectorAll("ol>li").forEach(function(e,i){
            if(i == id)
                e.classList.add("js--is-current");
            else
                e.classList.remove("js--is-current");
        })
    }

    this.getForm = function(){
        return form;
    }

    // change value
    form.querySelectorAll("input[type=radio]").forEach(function(input){
        input.addEventListener("change",function(){
            data[this.name] = parseInt(this.value);
        });
    })

    //submit form
    form.addEventListener("submit",function(e){
        e.preventDefault();
        if(data.grpID!=undefined){
            this.dispatchEvent("selectGrp",data)
        }
    }.bind(this));

    setCurrent(0);
    return this;
}

WeatherTech.Questions = function(vID,year,Grp){
    if(!(this instanceof WeatherTech.Questions))
        throw new Error("Uncaught TypeError: Questions is a construtor.");
        new EventHandler(this);
    var data = [],
        gID = Grp.GroupId
        choices = "",
        form =  document.createElement("form"),
        trans = WeatherTech.translator.trans;
    
    form.className="c--popup-questions";
    form.method = "POST";
    var content = '<ol></ol>';
    form.innerHTML = content; 

    function Q(data,i){
        this.value = "";
        this.element = document.createElement("li");
        this.element.dataset.name = data.OptionId;
        var content = '<div class="c--popup-title">'+data.Option+'</div>';
        new EventHandler(this);
        
        //Options list
        //if image
        if(data.Choices[0].ChoiceImage.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null){
        content+='<ul class="c--popup-choices----list mb2">' ;
        data.Choices.forEach(function(ch){               
            content +=      '<li class="c--popup-choices----item">'+
                                '<label for="'+data.OptionId+"_"+ch.ChoiceId+'">'+
                                    '<input id="'+data.OptionId+"_"+ch.ChoiceId+'" name="'+data.OptionId+'" value="'+ch.ChoiceId+'" type="radio">'+
                                    '<i class="fa fa-check"></i>'+
                                    '<img src="https:'+ch.ChoiceImage+'" alt="">'+
                                    '<span>'+ch.Choice+'</span>'+
                                '</label>'+
                            '</li>';
        });
        content +='</ul>';
        }
        else{
            content+='<div class="has-selector">' ;
            content+='<div class="select">' ;
            content+='<select name="'+data.OptionId+'">' ;
            content+='<option value="" selected disabled>'+trans('-Select an option-')+'</option>' ;
            data.Choices.forEach(function(ch){ 
                content+='<option value="'+ch.ChoiceId+'">'+ch.Choice+'</option>' ;
            });
            content +='</select>';
            content+='</div>' ;
            content+='</div>' ;
        }
        //btn after
        if(i!=0)                    content += '<a href="#" class="js--prev"><i class="fa fa-arrow-left"></i> '+trans('Back')+'</a>'                    
        if(i==Grp.Options.length-1) content += '<button class="btn btn--search2" type="submit">'+trans('Search')+'</button>';
        else                        content += '<a class="btn js--next">'+trans('Continue')+'</a>';

        this.element.innerHTML = content;

        this.element.querySelectorAll("input,select").forEach(function(input){
            input.addEventListener("change",function(){
                this.value = input.value;
                this.dispatchEvent("change");
            }.bind(this))
        }.bind(this))
                            
        return this;
    }

    if(Grp.Options.length<1){
        content='<div class="c--popup-title">'+trans('Wait a moment.')+'</div>';
        form.innerHTML = content; 
    }
    else Grp.Options.forEach(function(opt,i){
        var q = new Q(opt,i);
        form.querySelector("ol").appendChild(q.element);
        q.addEventListener("change",function(){
            data[i] = q.value;
            formatChoice();
        }.bind(this));
    })

    
    function formatChoice(){
        choices = "";
        data.forEach(function(s){
            if(choices!="")
                choices+="_";
            choices+=s;
        });
    }
    
    function setCurrent(id){
        form.querySelectorAll("ol>li").forEach(function(e,i){
            if(i == id)
                e.classList.add("js--is-current");
            else
                e.classList.remove("js--is-current");
        })
    }

    var submit = function(){
        
        var FD = new FormData();
        FD.append("Year",year);
        FD.append("VehicleId",vID);
        FD.append("GroupId",gID);
        FD.append("Choices",choices);
        var xHttp = new XMLHttpRequest();
        xHttp.onreadystatechange = function() {
            if (xHttp.readyState == XMLHttpRequest.DONE) {
                rsp = JSON.parse(xHttp.responseText);
                if(rsp.statusCode == 200 && rsp.Parts != undefined && rsp.Parts.length>0){
                    this.dispatchEvent("success",rsp)
                }
                else{
                    this.dispatchEvent("error",{"msg":"We cannot find any product."})
                }
            }
        }.bind(this)
        xHttp.open("POST",'/ajax/weathertechForm/');
        xHttp.send(FD);
        this.dispatchEvent("loading",{});
    }.bind(this);

    this.getForm = function(){
        return form;
    }

    // click next
    form.querySelectorAll(".js--next").forEach(function(e,i){
        e.addEventListener("click",function(e){
            e.preventDefault();
            if(data[i]!=undefined)
                setCurrent(i+1)
        });
    });
    // click previous
    form.querySelectorAll(".js--prev").forEach(function(e,i){
        e.addEventListener("click",function(e){
            e.preventDefault();
            setCurrent(i)
        });
    })

    //submit form
    form.addEventListener("submit",function(e){
        e.preventDefault();
        if(form.querySelectorAll("ol>li").length == data.length){
            submit();
        }
    }.bind(this));

    if(Grp.Options.length<1)
        submit();
    else
        setCurrent(0);
    return this;
}

WeatherTech.translator = new function(){
    lang = document.documentElement.lang;
    lang = lang.split('-')[0];
    var trans = {
        fr:{
            "Close" : "Fermer",
            "Back" : "Retour",
            "Continue" : "Continuer",
            "is not available for your" :"n'est pas disponible pour votre",
            "vehicle": "vehicule",
            "Search" : "Rechercher",
            "-Select an option-" : "-Choisissez une option-",
            'Year': 'Année',
            'Make': 'Fabricant',
            'Model': 'Modèle',
            'Wait a moment.': 'Veuillez patienter',
            'WeatherTech Products for:' : 'Produit WeatherTech pour:',
            'Please specify your vehicle' : 'Veuillez spécifier votre véhicule',
            'There is no product available for:' : 'Il n\'y a pas de produit disponible pour votre:'
        }
    }
    this.setLang = function($lang){
        lang = $lang;
    }
    this.add = function($lang,$key,$text){
        if(trans[lang] == undefined)
            trans[$lang] = {};
            trans[$lang][$key] = $text;
    }
    this.trans = function($text){
        if(trans[lang]!=undefined && trans[lang][$text]!=undefined)
            return trans[lang][$text];
        return $text;
    }
}

// POLYFILL REQUIRED
if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector || 
                                Element.prototype.webkitMatchesSelector;
if (!Element.prototype.closest)
    Element.prototype.closest = function(s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null); 
        return null;
    };

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}