/**********************************************
 * VEHICULE DEFINITION
 * 
 * Plugin which manipulates a year, make and
 * model dropdown lists to define a vehicule 
 * selection by the user
 ***********************************************/
(function ( $ ) {
 
    /**
     * Initializes the Vehicule Definition plugin.
     * @param object params Defines the controls of the plugins and how they should react.
     * The object contains 5 parameters : 
     *  - vehiculetype
     *  - year
     *  - make
     *  - model
     *  - complete (function that recieves the data parameter)
     * The vehiculetype parameters defines the type of vehicule that can be matched with the lists.
     * If this parameter is ommited, then all types of vehicule can me matched
     * The first year/make/model parameters define a dropdown list.
     * The complete parameter defines the function to execute when all 3 lists are filled. 
     * 
     * For each dropdown list, there must be 3 properties that defines it : 
     *  -- selector
     *  -- type (possible values are : AJAX, LIST, NONE)
     *  -- value
     * The selector represents the CSS jquery selector. The type represents the way the data
     * are fetched. AJAX will fetch from server and LIST will display the data listed in the value
     * property. The value property represents the AJAX url in case of a AJAX type or the list
     * of data to display in case of a LIST type. If type is NONE then the list will be left
     * with its initial data.
     * 
     * *** Currently only the AJAX is supported only for the make and model lists ***
     * 
     * 
     * Ex : 
     * {
     *     vehiculetype : 'car',
     *     year : {
     *         selector : '.js-year',
     *         type : LIST
     *         value : {
     *              firstid : 'value a thing',
     *              secondid : 'value something'
     *         }
     *     },
     *     make : {
     *         selector : '.js-year',
     *         type : AJAX
     *         value : 'http://www.something.com/some-url
     *     },
     *     model : {
     *         selector : '.js-model',
     *         type : AJAX
     *         value : 'http://www.something.com/some-url-a-second-time
     *     },
     *     complete : function(data){
     *          var year = data.year;
     *          var make = data.make;
     *          var model = data.model;
     *          
     *          ...
     *     }
     * }
     */
    $.fn.Definition = function(params) {
        var def = new Definition(this);
        /*if (!params.year || !params.make || !params.model)
        {
            return null;
        }
        
        vehicule.yearDef = params.year;
        vehicule.makeDef = params.make;
        vehicule.modelDef = params.model;

         
        
        if (params.vehiculetype)
        {
            vehicule.vehiculetype = params.vehiculetype;
        }
        
        if (params.complete)
        {
            vehicule.complete = params.complete;   
        }
        
        vehicule.BindControls();*/
        
        def.BindControls();
        
        return def;
    

        /**
         * Vehicule definition class. It defines the vehicule in it's whole
         * @param jQueryElement container jQuery Element that represents the container
         * of the dropdown lists
         */
        function Definition(container){
            this.container = container;
            var selectors = [];
            this.container.find('select').each(function(){
                selectors.push({
                    selector : this,
                    type : jQuery(this).data('url')?'ajax':'none',
                    value : jQuery(this).data('url'),
                    required : (typeof jQuery(this).data('require') == 'string')?jQuery(this).data('require').split(','):[],
                });
            })

            this.complete = function(data){};

            this.BindControls = function(){

                this.container.on('change', 'select', function(){
                    var current = this
                    selectors.forEach(function(el){
                        if(current == el.selector) return;
                        for(k in el.required){
                            var ref = container.find('[name="'+el.required[k]+'"]').val();
                            if(!ref)
                                return;
                        }
                        $(el.selector).removeAttr('disabled');
                        if(el.type == 'ajax'){
                            var _data = {};
                            is_complete = (params.complete != undefined)?true:false;
                            container.find('input,select,textarea').each(function(){
                                if(this.tagName.toLowerCase() =="select" && !$(this).val())  is_complete = false
                                if(
                                    ((this.type !="checkbox" && this.type !="radio") ||
                                    (this.type =="checkbox"  && this.type =="radio" && this.checked == true)) && this.value
                                )
                                _data[this.name] = this.value
                            });

                            if(is_complete){
                                params.complete(_data);
                            }
                            else $.ajax({
                                url:el.value,
                                dataType: 'JSON',
                                type: 'POST',
                                data: _data,
                                success: function(data) {
                                    var target = $(el.selector);
                                    var targetLabel = target.find('.js-label');
                                    var html = '';
                                    var _currentVal = el.selector.value;
                                    if (targetLabel)
                                    {
                                        html = '<option class="js-label" value="">' + targetLabel.html() + '</option>';
                                    }
                                    if (!data.error)
                                    {
                                        for (var i = 0; i < data.length; i++)
                                        {
                                            var text = typeof data[i] == "object"?data[i]['label']:data[i];
                                            var value = typeof data[i] == "object"?data[i]['value']:data[i];
                                            html += '<option value="' + value + '"'+(_currentVal===value?' selected':'')+'>' + text + '</option>';
                                        }
                                    }
                                    target.html(html);
                                    if(!target.val()){
                                        if (!Tools.IsMobile() && target.hasClass('js-select2')) {
                                            target.select2("open");
                                        } else {
                                            target.simulate("mousedown");
                                        }
                                    }

                                    

                                    console.log(container.find('select:not(:selected)').length)
                                }
                            });
                        }
                        //console.log(el.selector.value);
                    }.bind(this));
                    /*if (vehicule.makeDef.type && vehicule.makeDef.type.toUpperCase() == 'AJAX' && vehicule.makeDef.value)
                    {
                        var url = vehicule.makeDef.value;
                        target = vehicule.make;
                        var value = $(this).val();
                        var segment = $('.js-segment-tab.active').attr('id');
                        

                         if (segment == 'light-vehicles') {
                                vehicule_segment = 'Car,Truck,Van,ATV,Offroad Motorcycle,Snowmobile,Utility Vehicle,Scooter';
                           } else if (segment == 'heavy-trucks') {
                                vehicule_segment = 'Medium/Heavy Truck'; 
                           }
                           
                        if ($('.container-search-tool--modal.promo').length) {
                            vehicule_segment = 'Car,Truck,Van,ATV,Offroad Motorcycle,Snowmobile,Utility Vehicle,Scooter,Medium/Heavy Truck'; 
                        }
                        
                        $.ajax({
                            url:url,
                            dataType: 'JSON',
                            type: 'POST',
                            data: {
                                year : value,
                                type : vehicule_segment
                            },
                            success: success
                        });
                    }*/
                });
            };

            /**
             * Fills all the list defined as type LIST in their definition
             */
            this.FillLists = function()
            {
                var html;
                var targetLabel;
                var fillList = function(list, data){
                    targetLabel = list.find('.js-label');
                    html = '';
                    if (targetLabel)
                    {
                        html = '<option class="js-label" value="">' + targetLabel.html() + '</option>';
                    }

                    for (var key in data)
                    {
                        html += '<option value="' + key + '">' + data[key] + '</option>';
                    }

                    list.html(html);
                };
                if (this.yearDef.type && this.yearDef.type.toUpperCase() == 'LIST')
                {
                    fillList(this.year, this.yearDef.value);
                }
                if (this.makeDef.type && this.makeDef.type.toUpperCase() == 'LIST')
                {
                    fillList(this.make, this.makeDef.value);
                }
                if (this.modelDef.type && this.modelDef.type.toUpperCase() == 'LIST')
                {
                    fillList(this.model, this.modelDef.value);
                }
            };
        }
    };
 
}( jQuery ));