/**
 * HOW TO USE
 * 
 * ("text to translate")
 * ("text to translate","lg")
 * ("text to translate",["repalce %s"])
 * ("text to translate",["repalce %s"],'lg')
 * 
 * $("div")._e("text to translate");
 * document.getElementById("#to_trans")._e("text to translate");
 * __("text to translate");
 * Translator.trans("text to translate");
 * Translator.getLang();
 * Translator.setLang("lg");
 * Translator.add("lg","text to translate","Translator Responce");
 * Translator.add("lg","text to translate",{
 *  0: "0 as param[0]"
 *  1: "1 as param[0]"
 *  '': "everyting else as param[0]"
 * });
 * 
 */

function TranslatorJS(){
    var lang = "en";
    if(document.querySelector("html").lang != undefined)
        lang = document.querySelector("html").lang;
    var trans = {
        en:{
            'View larger map' : 'View larger map',
            'Review' : 'Review',
            '%s reviews' : {
                0: 'No reviews',
                1: '1 review',
                '': '%s reviews'
            },
            'Go' : 'Go',
            'All Categories' : 'All Categories',
            'Year' : 'Year',
            'Make' : 'Make',
            'Model' : 'Model',
            'All Brands' : 'All Brands',
            'Brands' : 'Brands',
            'Part types' : 'Part types',
            'Parts' : 'Parts',
            'Add to cart' : 'Add to cart',
            'View cart' : 'View cart',
            'Advanced search': 'Advanced search',
        },
        fr:{
            'View larger map' : 'Agrandir la carte',
            'Review' : 'Avis',
            '%s reviews' : {
                0: 'No reviews',
                1: '1 review',
                '': '%s reviews'
            },
            'Go' : 'Go',
            'All Categories' : 'Toute les catégories',
            'Year' : 'Année',
            'Make' : 'Marque',
            'Model' : 'Modèle',
            'All Brands' : 'Toute les marques',
            'Brands' : 'Marques',
            'Part types' : 'Types de pièces',
            'Parts' : 'Pièces',
            'Add to cart' : 'Ajouter au panier',
            'View cart' : 'Voir le panier',
            'Advanced search': 'Recheche avancée',
            'No result found for "%s"': 'Aucun résultat trouvé pour "%s"',
        }
    }

    function replaceAll($string,$array){
        count = ($string.match(/%s/g) || []).length;
        if(count && count!=$array.length)
            throw new Error("The number of parameters does not match the number of variables.");
        for(var i = 0;i<count;i++){
            $string = $string.replace("%s",$array[i])
        }
        return $string
    }

    this.set = function($lang){
        if(typeof $lang != "string") throw new TypeError("First parameter need to be a string");
        return lang = $lang;
    }
    this.getLang = function(){
        return lang;
    }
    this.add = function($lang,$key,$text){
        if(typeof $lang != "string" || typeof $key != "string") throw new TypeError("All parameter need to be a string");
        if(trans[lang] == undefined)
            trans[$lang] = {};
            trans[$lang][$key] = $text;
    }
    this.trans = function($text,$param,$lang){
        if(typeof $text != "string") throw new TypeError("First parameter need to be a string");
        if(typeof $lang != "string") $lang = $param;
        if(typeof $lang != "string") $lang = lang;
        if(!Array.isArray($param)) $param = [];
        if(trans[$lang]!=undefined && trans[$lang][$text]!=undefined){
            if( typeof trans[$lang][$text] == "string")
                return replaceAll(trans[$lang][$text],$param);
            else if($param.length && !isNaN($param[0]) ){
                if(trans[$lang][$text][$param[0]] != undefined){
                    return replaceAll(trans[$lang][$text][$param[0]],$param);
                }
                else{
                    return replaceAll(trans[$lang][$text][''],$param);
                }
            }
        }
        return replaceAll($text,$param);
    }
};
// PHP like
window.Translator = new TranslatorJS();
// Wordpress like
window.__ = window.Translator.trans;
if (window.jQuery) jQuery.fn._e = function($t,$p,$l) { this.html( window.__($t,$p,$l) ); };
Element.prototype._e = function($t,$p,$l) {this.innerHTML = window.__($t,$p,$l);};
// Twig like
window.trans = window.Translator.trans;


// SELECT 2
jQuery(document).ready(function(){
    (function(){if(jQuery&&jQuery.fn&&jQuery.fn.select2&&jQuery.fn.select2.amd)var e=jQuery.fn.select2.amd;return e.define("select2/i18n/fr",[],function(){return{inputTooLong:function(e){var t=e.input.length-e.maximum,n="Supprimez "+t+" caractère";return t!==1&&(n+="s"),n},inputTooShort:function(e){var t=e.minimum-e.input.length,n="Saisissez "+t+" caractère";return t!==1&&(n+="s"),n},loadingMore:function(){return"Chargement de résultats supplémentaires…"},maximumSelected:function(e){var t="Vous pouvez seulement sélectionner "+e.maximum+" élément";return e.maximum!==1&&(t+="s"),t},noResults:function(){return"Aucun résultat trouvé"},searching:function(){return"Recherche en cours…"}}}),{define:e.define,require:e.require}})();
});