// REQUIRED EVENTHANDLER.JS

Wiper = function(url,year, make, model){
    var _form = $('<div class="c-smart-search flex-j-center" style="display:none"></div>')
    var _xhr = null;
    var _timeout=null;

    if(isNaN(parseInt(year))) year = '';
    if(typeof make != 'string') make = '';
    if(typeof model != 'string') model = '';
    this.show = function(){
        render = _form;
        $('body').append(render);
        ref = this;
        render.fadeIn(function(){
            /*document.ScrollContoller.disableScroll();
            if(ref instanceof HTMLInputElement &&  ref.tagName.toLowerCase() == 'input'){
                _form.find('.js-search-ai input[type=search]').val(ref.value);
                ref.value = '';
            }
            _form.find('.js-search-ai input[type=search]').focus();*/
        });

        callAPI('/ajax/WiperForm/',{year:year,make:make,model:model},function($data){
            var modal_vehicle = $('<div class="c-smart-search--modal append"></div>');
            if($data.data != null){
                displayGroups(year+' '+make+' '+model,$data.data);
            }
            else{
                _form.append('<div class="c-smart-search--title">'+Wiper.translator.trans('Please specify your vehicle')+'</div>');
                modal_vehicle.append(vehicleData($data.vehicle,$data.step2,$data.isNA,$data.token));
            }
            _form.append(modal_vehicle);
        })
    }

    this.hide = function(){
        if(_xhr)_xhr.abort();
        if(_timeout) clearTimeout(_timeout);
        _form.find('.input-search-bar button.search .fa').addClass('fa-search').removeClass(' fa-circle-o-notch fa-spin fa-pencil');
        _form.fadeOut(function(){
            _form.find('.c-smart-search--modal.append').remove();
            _form.find('.c-smart-search--title').remove();
        })
        document.ScrollContoller.enableScroll();
    }

    _form.on('click','button.close',function(e){
        e.preventDefault();
        this.hide();
    }.bind(this));


    function callAPI(uri,data,callback){
        var FD = new FormData();
        for(var key in data){
            FD.append(key,data[key]);
        }

        var request;
        if (window.ActiveXObject)
            request = new ActiveXObject('Microsoft.XMLHTTP');
        else
            request = new XMLHttpRequest();

        $('body').append([
            '<div class="ajax-loading" style="display:block">',
            '<div class="overlay"></div>',
            '<div class="content" style="text-align: center">',
            '<div class="content__align">',
            '<i class="fa fa-circle-o-notch fa-spin fa-3x white" aria-hidden="true"></i>',
            '</div>',
            '</div>',
            '</div>'
        ].join(''));
        
        request.open("POST",uri);
        request.onreadystatechange = function() {
            if (request.readyState == XMLHttpRequest.DONE) {
                $('body').children('.ajax-loading').remove();
                if(callback!= undefined){
                    callback(JSON.parse(request.responseText));
                }
            }
        };
        request.send(FD);
    }

    // Year make model
    function vehicleData($vehicle){
        var container = $('<form class="vehicule-definition" method="POST"></form>');
        var selection = container.find('[name=selection]')
        container.on('submit',function(e){
            e.preventDefault();
            if(!year.val()){
                year.open();
                return false;
            }
            else if(!make.val()){
                make.open();
                return false;
            }
            else if(!model.val()){
                model.open();
                return false;
            }
           var redirect = url.replace("$1",year.val()).replace("$2",make.val()).replace("$3",model.val())

            window.location.replace(redirect);
        })
        if($vehicle.year){
            var year = new vehicleFieldData($vehicle.year,'js-year year',Wiper.translator.trans('Year'));
            container.append(year.getObject());
            year.addEvent(function(){
                if(!make.update({year:year.val()}))
                    model.update({year:year.val(), make:make.val()});
                container.submit();
            });
        }
        if($vehicle.make){
            var make = new vehicleFieldData($vehicle.make,'js-make make',Wiper.translator.trans('Make'));
            container.append(make.getObject());
            make.addEvent(function(){
                model.update({year:year.val(), make:make.val()});
                container.submit();
            });
        }
        if($vehicle.model){
            var model = new vehicleFieldData($vehicle.model,'js-model model',Wiper.translator.trans('Model'));
            container.append(model.getObject());
            model.addEvent(function(){
                container.submit();
            });
        }
        container.append('<button class="close js-close"><i class="fa fa-close"></i></button>');
        return container;
    }

    function vehicleFieldData($field, $class,$text){
        var render = false;
        if(!isNaN($field) || typeof $field == 'string'){
            render = $('<div class="field-group"><input type="text" class="'+$class+'" value="'+$field+'" readonly ></div>');
        }
        else if(Array.isArray($field)){
            render = $('<div class="field-group selectbox "><select class="'+$class+'" tabindex="-1" aria-hidden="true"><option value="">'+$text+'</option></select></div>');
            $field.forEach(function(el){
                render.find('select').append('<option class="js-label" value="'+el+'">'+el+'</option>')
            });
            render.find('select').select2();
        }
        else if($field.ajax){
            render = $('<div class="field-group selectbox"><select class="'+$class+'" tabindex="-1" aria-hidden="true"><option value="">'+$text+'</option></select></div>');
            render.find('select').select2();
        }

        this.getObject = function(){
            return render;
        }
        this.addEvent = function(calback){
            return render.find('input,select').on('change',calback);
        }
        this.val = function(){
            return render.find('input,select').val();
        }

        this.open = function(){
            var target = render.find('select');
            if(!target.val()){
                target.select2("close");
                target.select2("open");
            }
        }

        this.update = function($data){
            if($field.ajax){
                target = render.find('select')
                target.attr('disabled',true);
                callAPI($field.ajax, $data,function($rsp){
                    $key = $text.toLowerCase();
                    switch($key){
                        case 'année':
                            $key = 'year';
                            break;
                        case 'fabricant':
                            $key = 'make';
                            break;
                        case 'modèle':
                            $key = 'model';
                            break;
                    }
                    var html = '<option class="js-label" value="">' + $text + '</option>';
                    for (var i = 0; i < $rsp.vehicle[$key].length; i++)
                    {
                        html += '<option value="' + $rsp.vehicle[$key][i] + '">' + $rsp.vehicle[$key][i] + '</option>';
                    }
                    target.html(html);
                    target.attr('disabled',false);
                    if(target.find('option').length>1){
                        this.open();
                    }
                }.bind(this))
                return true;
            }
            return false;
        }
    }


    //function Get Question
    function displayGroups(vName,$group){
        _form.find('.smart-search-result.append').remove();
        if($group === null){
            return;
        }
        var block = $('<div class="c-smart-search--modal smart-search-result append"><div class="box">'+(!_form.find('button.close').length?'<button class="close"><i class="fa fa-close"></i></button>':'')+'<div class="right-side"><div class="h3"><b>'+Wiper.translator.trans('Wiper Products for:')+'</b> '+vName+'</div><ul class="flex px2"></ul></div></div></div>');
        _form.append(block);
        $group.Groups.forEach(function(e){
            var li = document.createElement("li");
            li.className="l--banner-Wiper-items";
            var link = document.createElement("a");
            link.className = 'box';
            link.href = "#";
            link.innerHTML = '<img src="'+e.GroupImage+'" />'+'<span>'+e.GroupName+'</span>';
            link.addEventListener("click",function(){
                var popup = new Wiper.Popup($group.VehicleId,$group.Year,{Groups:[e],Status:$group.Status,VehicleId:$group.VehicleId,Year:$group.Year,statusCode:$group.statusCode});
                popup.setVehiculeName(vName)
                popup.toggle();
                popup.addEventListener("success",function(e){
                    var form = $('<form action="'+$group.URL+'" method="post" style="display:none">' +
                    '<textarea name="Selection"></textarea>' +
                    '</form>');
                    $('body').append(form);
                    form.find('textarea').val(JSON.stringify(e.detail.Parts));
                    form.submit();
                    $('body').append([
                        '<div class="ajax-loading" style="display:block">',
                        '<div class="overlay"></div>',
                        '<div class="content" style="text-align: center">',
                        '<div class="content__align">',
                        '<i class="fa fa-circle-o-notch fa-spin fa-3x white" aria-hidden="true"></i>',
                        '</div>',
                        '</div>',
                        '</div>'
                    ].join(''));
                });
            });
            li.appendChild(link);
            block.find('ul').append(li);
        })
    }
}

Wiper.translator = new function(){
    lang = document.documentElement.lang;
    lang = lang.split('-')[0];
    var trans = {
        fr:{
            "Close" : "Fermer",
            "Back" : "Retour",
            "Continue" : "Continuer",
            "is not available for your" :"n'est pas disponible pour votre",
            "vehicle": "vehicule",
            "Search" : "Rechercher",
            "-Select an option-" : "-Choisissez une option-",
            'Year': 'Année',
            'Make': 'Fabricant',
            'Model': 'Modèle',
            'Wait a moment.': 'Veuillez patienter',
            'Wiper Products for:' : 'Produit Wiper pour:',
            'Please specify your vehicle' : 'Veuillez spécifier votre véhicule',
            'There is no product available for:' : 'Il n\'y a pas de produit disponible pour votre:'
        }
    }
    this.setLang = function($lang){
        lang = $lang;
    }
    this.add = function($lang,$key,$text){
        if(trans[lang] == undefined)
            trans[$lang] = {};
            trans[$lang][$key] = $text;
    }
    this.trans = function($text){
        if(trans[lang]!=undefined && trans[lang][$text]!=undefined)
            return trans[lang][$text];
        return $text;
    }
}

// POLYFILL REQUIRED
if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector || 
                                Element.prototype.webkitMatchesSelector;
if (!Element.prototype.closest)
    Element.prototype.closest = function(s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null); 
        return null;
    };

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}