$(function(){
    $('.slickslide-as-thumb').each(function(){
        var clone =$(this).clone().removeClass('slickslide-as-thumb').addClass('slickslide-nav').insertAfter(this).slick({
          dots: false,
          infinite: true,
          speed: 500,
          fade: false,
          slide: 'li',
          cssEase: 'linear',
          centerMode: true,
          slidesToShow: 3,
          adaptiveHeight: true,
          autoplay: false,
          autoplaySpeed: 4000,
          focusOnSelect: true,
          asNavFor: this,
        });
        $(this).slick({
          dots: false,
          infinite: true,
          speed: 500,
          fade: true,
          slide: 'li',
          cssEase: 'linear',
          centerMode: true,
          slidesToShow: 1,
          adaptiveHeight: true,
          autoplay: false,
          autoplaySpeed: 4000,
          asNavFor: clone,
        });
    })
})