$(function () {
    $('.video-thumb-overlay').click(showVideoPlayerModal)
    $('.video-modal .btn-close').click(closeVideoPlayerModal)
})

//Handlers
function closeVideoPlayerModal() {
    $(".video-modal").hide();
    setSrc("");
}
function showVideoPlayerModal(e) {
    e.preventDefault();
    $(".video-modal").show()
    var videoSRC = $(this).attr("src");
    var videoSRCauto = videoSRC + "?autoplay=1";
    setSrc(videoSRCauto);
}

function setSrc(src) {
    $(".video-modal iframe").attr('src', src);
}
