var validationTimeout;
var goButton;
var partTextfield;
jQuery('document').ready(function() {
    jQuery('.js-search-part-number').on('keyup', '.js-search-part-number-text', function(){
        if(jQuery(this).val().length > 2) {
            goButton = jQuery(this).closest('.js-search-part-number').find('.js-go-button');
            goButton.removeAttr('disabled');
            goButton.addClass('valid');
        } 
    }); 
    jQuery('.js-search-part-number').on('click', '.js-go-button', function(e) {
        e.preventDefault();
        partTextfield = jQuery('.js-search-part-number-text').val();
        url = jQuery('.js-search-part-number-text').data('url'); 
        lang = jQuery('html').attr('lang');
        $.ajax({
            url: url,
            dataType: 'JSON',
            type: 'POST',
            data: {
                partNumber : partTextfield
            },
            success: function(data) {
                if(data.valid == 1) {
                    jQuery('.js-search-part-number').submit();
                } else {

                    jQuery('.js-search-part-number-text').val('');
                    jQuery('.js-search-part-number-text').blur(); 
                    if(lang == 'en') {
                        jQuery('.js-search-part-number-text').attr("placeholder", "No result found.");
                    } else {
                        jQuery('.js-search-part-number-text').attr("placeholder", "Aucun résultat trouvé.");
                    }
                }
            },
            error: function() {
                jQuery('.js-search-part-number-text').val('');
                jQuery('.js-search-part-number-text').blur();
                if(lang == 'en') {
                    jQuery('.js-search-part-number-text').attr("placeholder", "No result found.");
                } else {
                    jQuery('.js-search-part-number-text').attr("placeholder", "Aucun résultat trouvé.");
                }

            }
        });
    });
});