jQuery('document').ready(function(){
    $('.js-weathertech').click(function(e){
        new WeatherTech().show();
        return false;
    });
    $('.js-light-vehicle').click(function(){
        $('js-container-modal').removeClass('active');
        $('js-container-light-vehicles').addClass('active');
    });
    $('.js-unitool').click(function(){
        location.href = "/blog/promo/unitool/";
    });
    $('.js-wiper').click(function(e){
        new Wiper($(this).data('url')).show();
        return false;
    });
    $('.js-segment-tab').click(function(){
        $('.js-segment-tab').removeClass('active');
        $(this).addClass('active');
    });

});