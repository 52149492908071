/**********************************************
 * GARAGE LIST
 * 
 * Plugin which enables the user to select 
 * a vehicule from its garage
 ***********************************************/
(function ( $ ) {
    /**
     * Initializes the GarageList plugin. It list all the vehicules of the user's garage
     * @param object List of parameters of the plugin.
     * The object contains 3 parameters : 
     *  - type (possible values are AJAX, LIST, NONE)
     *  - value
     *  - change
     *  
     * The type represents the way the data is fetched. AJAX will fetch from server and 
     * LIST will display the data listed in the value property. The value property
     * represents the AJAX url in case of a AJAX type or the list of data to display 
     * in case of a LIST type. If type is NONE then the list will be left with its initial data.
     * 
     * The change parameter is the action to execute when the list change has been successfull
     * 
     * Ex : 
     * {
     *      type : LIST
     *      value : [
     *          {id: 33, year: 2010, make: 'Chevrolet', model: 'CobaltSS'}
     *          {id: 34, year: 2005, make: 'Acura', model: 'TSX'},
     *          ...
     *      ],
     *      change : function(data) {
     *          var choseValue = data.value;
     *          
     *          ...
     *      }
     * }
     * 
     * METHODS
     * 
     * - Refresh() :
     * Refreshes the current data of the list (AJAX only). If type is LIST, it puts back
     * the initial listed data.
     */
    $.fn.GarageList = function(params) {
        this.type = 'NONE';
        if (params.type) {
            this.type = params.type;
        }
        this.value = [];
        if (params.value){
            this.value = params.value;
        }
        this.change = function(data){};
        
        var list = this;
        
        this.Refresh = function(data) {
            if (list.type.toUpperCase() == 'AJAX')
            {
                var type = '';
                if (list.data('type'))
                {
                    type = list.data('type');
                }
                $.ajax({
                    url:this.value,
                    dataType: 'JSON',
                    type: 'POST',
                    data : {
                        type: type
                    },
                    success: function(data) {
                        if (!data.error)
                        {
                            list.ParseData(data);
                        }
                    }
                });
            }
            else if (list.type.toUpperCase() == 'LIST')
            {
                list.ParseData(list.value);
            }
        };
        
        this.ParseData = function(data) {
            var targetLabel = list.find('.js-label');
            var html = '';
            if (targetLabel)
            {
                html = '<option class="js-label" value="">' + targetLabel.html() + '</option>';
            }
            for (var key in data)
            {
                html += '<option value="' + data[key].id + '">' + data[key].year + ' ' + data[key].make + ' ' + data[key].model + '</option>';
            }
            list.html(html);
        };
        
        this.Refresh();
        
        this.on('change', function(){
            list.change({value : $(this).val()});
        });
        
        return this;
    };
}( jQuery ));