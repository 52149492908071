jQuery('document').ready(function(){
    /*************************************************
    * VEHICULE DEFINITION
    ************************************************/
    jQuery('.js-garage .js-vehiculedefinition').VehiculeDefinition({
        vehiculetype : jQuery('.js-garage .js-vehiculedefinition').data('type'),
        year : {
            selector : '.js-year',
            type : 'none'
        },
        make : {
            selector : '.js-make',
            type : 'ajax',
            value : $('.js-vehiculedefinition').find('.js-make').data('url')
        },
        model : {
            selector : '.js-model',
            type : 'ajax',
            value : $('.js-vehiculedefinition').find('.js-model').data('url')
        }
    });
    
    /*************************************************
    * GARAGE LIST
    ************************************************/
    
    jQuery('.js-garagelist').GarageList({
        type : 'none',
        change : function(data) {}
    });


    /*************************************************
    * GARAGE WIDGET
    ************************************************/
    jQuery('.js-garage').on('click', '.js-removevehicule', function(){

        var garage = $(this).parents('.js-garage');
        var row = $(this).siblings('.js-vehicule-data');
        var year = row.find('.js-row-year').data('value');
        var make = row.find('.js-row-make').data('value');
        var model = row.find('.js-row-model').data('value');
        var url = $(this).data('url');
        var novehicule = garage.find('.js-novehicule');
        var vehiculeDef = garage.find('.js-vehiculedefinition');
        var addAVehicule = garage.find('.js-addvehicule');
        var optionValue = year+'|'+make+'|'+model;

        $.ajax({
            url:url,
            dataType: 'JSON',
            type: 'POST',
            data: {
                year : year,
                make : make,
                model : model
            },
            success: function(data) {
                if (!data.error) {
                    garage.find('.js-vehiculerow').each(function(){
                        if ($(this).find('.js-row-year').data('value') == year && $(this).find('.js-row-make').data('value') == make && $(this).find('.js-row-model').data('value') == model)
                        {
                            $(this).remove();
                            jQuery('.js-search-by-garage-select option[value="' + optionValue + '"]').remove();
                        }
                    });
                    if (garage.find('.js-vehiculerow').length == 0)
                    {
                        novehicule.removeClass('hide');
                    }
                    else
                    {
                        novehicule.addClass('hide');
                    }
                    addAVehicule.removeClass('hide');
                    vehiculeDef.removeClass('hide');
                }
            }
        });
    });
     jQuery('.breadcrumb').on('mouseover', '.js-container-content-step', function(){
        jQuery(this).siblings('.step-number').addClass('step-number-hover');
     });
      jQuery('.breadcrumb').on('mouseleave', '.js-container-content-step', function(){
        jQuery(this).siblings('.step-number').removeClass('step-number-hover'); 
     })
    jQuery('body').on('click', '.js-addvehicule, .js-button-save-vehicle', function(){
       
        var url = $(this).data('url');
        var item_clicked = $(this);
        var check_class_item_click = item_clicked.hasClass("js-button-save-vehicle");
       
        var garage = $('.js-garage');
        var rowsContainer = garage.find('.js-rows-container');
        var template = garage.find('.js-vehiculerow-template');
        var vehiculeDef = garage.find('.js-vehiculedefinition');
        var addAVehicule = garage.find('.js-addvehicule');

       if(item_clicked.hasClass("js-button-save-vehicle")){
            var container = $(this);
        }else{
            var container = garage;
        }

        var year = container.find('.js-year');
        var make = container.find('.js-make');
        var model = container.find('.js-model');
 

        var error = false;
        year.removeClass('has-error');
        make.removeClass('has-error');
        model.removeClass('has-error');
        if (year.val() == "")
        {
            year.addClass('has-error');
            error = true;
        }
        if (make.val() == "")
        {
            make.addClass('has-error');
            error = true;
        }
        if (model.val() == "")
        {
            model.addClass('has-error');
            error = true;
        }
      

        if (!error)
        {
            
             if(check_class_item_click){
                var overlay = jQuery('.js-save-overlay');
                var overlayLoading = overlay.find('.js-save-overlay-loading');
                var overlayBox = overlay.find('.js-save-overlay-box');
                var overlaySuccess = overlayBox.find('.js-success');
                var overlayError = overlayBox.find('.js-error');
                overlay.removeClass('hide');
                overlayLoading.removeClass('hide');
             }

            $.ajax({
                url:url,
                dataType: 'JSON',
                type: 'POST',
                data: {
                    year : year.val(),
                    make : make.val(),
                    model : model.val()
                },
                success: function(data) {

                    if (!data.error){
                  

                            var row = template.clone();
                            var yearCell = row.find('.js-row-year');
                            var makeCell = row.find('.js-row-make');
                            var modelCell = row.find('.js-row-model');

                            yearCell.html(year.val());
                            yearCell.attr('data-value', year.val());
                            makeCell.html(make.val());
                            makeCell.attr('data-value', make.val());
                            modelCell.html(model.val());
                            modelCell.attr('data-value', model.val());

                            row.removeClass('hide');
                            row.removeClass('js-vehiculerow-template');
                            row.addClass('js-vehiculerow');
                            rowsContainer.append(row);


                            if (check_class_item_click) {
                                overlayLoading.addClass('hide');
                                overlayBox.removeClass('hide');
                                overlaySuccess.removeClass('hide');
                                setTimeout(function(){
                                        overlay.animate({
                                            opacity:0
                                        }, 300, function(){
                                            setTimeout(function(){overlay.addClass('hide');}, 250);
                                        });
                                        overlayBox.animate({
                                            opacity:0
                                        }, 300, function(){
                                            setTimeout(function(){overlayBox.addClass('hide');}, 250);
                                        });
                                    },2000); 
                                }
                            
                            if (data.more == 0) {
                                addAVehicule.addClass('hide');
                                vehiculeDef.addClass('hide');
                            } else {
                                addAVehicule.removeClass('hide');
                                vehiculeDef.removeClass('hide');
                            }

                    
                    
                    jQuery('.js-search-by-garage-select').append('<option value="' + year.val() + '|' + make.val() + '|' + model.val() + '">' + year.val() + ' ' + make.val() + ' ' + model.val() + '</option>');
                    jQuery('.js-button-save-vehicle, .js-container-step-save').fadeOut();
                    model.val('').trigger('change.select2');
                    make.val('').trigger('change.select2');
                    year.val('').trigger('change.select2');

                    }else{

                        if(check_class_item_click){
                            overlayLoading.addClass('hide');
                            overlayBox.removeClass('hide');
                            overlayError.removeClass('hide');
                             setTimeout(function(){
                                    overlay.animate({
                                        opacity:0
                                    }, 300, function(){
                                        setTimeout(function(){overlay.addClass('hide');}, 250);
                                    });
                                    overlayBox.animate({
                                        opacity:0
                                    }, 300, function(){
                                        setTimeout(function(){overlayBox.addClass('hide');}, 250);
                                    }); 
                                },2000); 
                        }

                    }
                }
            });

         
        }
    });
    /*************************************************
    * BRANDS PAGE (listing)
    ************************************************/
    jQuery('.js-brands').on('click', '.js-letter-page', function(){
        if (jQuery(this).hasClass('selected')){
            return false;
        }
        var brands = jQuery('.js-brands');
        var value = jQuery(this).data('value');
        
        if (value == 'all')
        {
            brands.find('.js-brand').removeClass('hide');
            brands.find('.js-letter-separator').removeClass('hide');
        }
        else
        {
            brands.find('.js-brand').addClass('hide');
            brands.find('.js-letter-separator').addClass('hide');
            brands.find('.js-brand[data-category=' + value + '], .js-letter-separator[data-category=' + value + ']').removeClass('hide');
        }
        
        var active = 0;
        brands.find('.js-brand').each(function(){
            if (!jQuery(this).hasClass('hide'))
            {
                active++;
            }
        });
        
        if (active === 0)
        {
            brands.find('.js-no-brands').removeClass('hide');
        }
        else
        {
            brands.find('.js-no-brands').addClass('hide');
        }
        
        brands.find('.js-letter-page').removeClass('selected');
        jQuery(this).addClass('selected');
    });
    /************************************************/
});