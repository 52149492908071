/*     ______                 __  __  __                ____         
 *    / ____/   _____  ____  / /_/ / / /___ _____  ____/ / /__  _____
 *   / __/ | | / / _ \/ __ \/ __/ /_/ / __ `/ __ \/ __  / / _ \/ ___/
 *  / /___ | |/ /  __/ / / / /_/ __  / /_/ / / / / /_/ / /  __/ /   
 * /_____/ |___/\___/_/ /_/\__/_/ /_/\__,_/_/ /_/\__,_/_/\___/_/.js
 * 
 * @licence The Unlicense, http://unlicense.org/
 * @version 1.0
 * @author Vincent Chevalier, cvincent.ca
 * @update 2018-07-30
 * 
 * BASE SUPPORT : IE9+
 *
 * 1. What is EventHandler
 * EventHandler transform each javascript object you want into a event holder like DOM or window.
 *
 * 2. Why use Update
 * EventHandler is very usefull when a function need a unknown number of callback and need to be execute one time.
 * For example, when you click on a button, you can attach multiple function on it. But a function Object don't have equivalent.
 *
 * 3. How use it
 *  1. First create a variable holder like "new EventHanlder(this)".
 *  2. Use your object to add Event and trigger it like "this.addEventListener("example",test)" 
 *
 * 4. Properties
 *  Create an occurrence
 *  > new EventHandler(OPTIONAL obj = window,OPTIONAL private = false)
 *  < EventHandler
 *
 *  Add function to EventListener
 *  > obj.addEventListener(string, function)
 *  < obj
 *
 *  Remove function to EventListener
 *  > obj.removeEventListener(OPTIONAL string, OPTIONAL function)
 *  < obj
 *
 *  Trigger an Event
 *  if (privite = false)
 *  > obj.dispatchEvent(string, OPTIONAL json)
 *  < void
 *
 *  else
 *  > EventHandler.dispatchEvent(string, OPTIONAL json)
 *  < void
 *
 * 5. Example (do not take it literally)
function PNG(){
  if(!(this instanceof PNG))
    throw new Error("Uncaught TypeError: PNG is a construtor.");
  new EventHandler(this); OR handler = new EventHandler(this,true);
  ...
  this.dispatchEvent("test"); OR handler.dispatchEvent("test");
  ...
}
function test(){
  console.log("bleu");
}
var object = new PNG();
object.addEventListener("test",test);
*/

function EventHandler(obj,private){
    if(!(this instanceof EventHandler))
      throw new Error("Uncaught TypeError: EventHandler is a construtor.");
    if(obj == undefined)
      obj = window;
    if(obj.addEventListener!=undefined){
      console.error("Uncaught TypeError: This object already have event handler.")
      return;
    }
  
    var element = document.createElement("div"),
        evt = new CustomEvent("tic"),
        histy = [];
    
    this.dispatchEvent = function(type, obj){
      evt.initCustomEvent(type,false,false,obj)
      element.dispatchEvent(evt);
    }
    if(private != true){
      obj.dispatchEvent = this.dispatchEvent;
    }
    
    obj.addEventListener = function(t,f) {
      if(t == undefined)
        throw new Error("Uncaught TypeError: Failed to execute 'addEventListener' on 'EventHandler': 2 arguments required, but only 0 present.");
      else if(f == undefined)
        throw new Error("Uncaught TypeError: Failed to execute 'addEventListener' on 'EventHandler': 2 arguments required, but only 1 present.");
      else if(typeof f !="function")
        throw new Error("Uncaught TypeError: Failed to execute 'addEventListener' on 'EventHandler': 2 argument required a function.");
      
      histy.forEach(function(e,i){
        if(e.type == t && e.fnc == f){
          throw new Error("Uncaught TypeError: Failed to execute 'addEventListener' on 'EventHandler': This action is already registred.");
        }
      });
      histy.push({type: t, fnc: f})
      element.addEventListener(t, f, !1);
      return obj;
    };
  
    obj.removeEventListener = function(t,f) {
      var error = "";
      if(f != undefined && typeof f !="function")
        throw new Error("Uncaught TypeError: Failed to execute 'removeEventListener' on 'EventHandler': 2 argument required a function.");
  
      if(f != undefined){
        histy.forEach(function(e,i){
          if(e.type == t && e.fnc == f){
            histy.splice(i, 1);
            element.removeEventListener(e.type, e.fnc, !1);
          }
        });
      }
      else if(t != undefined){
        for (var i = histy.length-1; i >=0; i--) {
          if(histy[i].type == t){
            element.removeEventListener(histy[i].type, histy[i].fnc, !1);
            histy.splice(i, 1);
          }
        }
      }
      else{
        for (var i = histy.length-1; i >=0; i--) {
          element.removeEventListener(histy[i].type, histy[i].fnc, !1);
          histy.splice(i, 1);
        }
      }
      return obj;
    };
    return this;
  }
  
  // CustomEvent support required for old browser
  (function () {
    if ( typeof window.CustomEvent === "function" ) return false;
    function CustomEvent ( event, params ) {
      params = params || { bubbles: false, cancelable: false, detail: undefined };
      var evt = document.createEvent( 'CustomEvent' );
      evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
      return evt;
    }
    CustomEvent.prototype = window.Event.prototype;
    window.CustomEvent = CustomEvent;
  })();